	// Adapted from https://github.com/udacity/ud891/tree/gh-pages/lesson2-focus/07-modals-and-keyboard-traps/solution

// Will hold previously focused element
let focusedElementBeforeMenu;

// Find the modal and its overlay
const mobileMenu = document.querySelector('[data-mobile-menu]');
const menuOverlay = document.querySelector('.modal-overlay');

// Attach listeners to all the modal toggles
const menuToggle = document.querySelector('[data-open-menu]');

// for(let i = 0; i < menuToggles.length; i++) {
	menuToggle.addEventListener('click', openMenu);
// }

/**
 * Opens the modal and traps focus.
 */
 function openMenu() {
  // Save current focus
  focusedElementBeforeMenu = document.activeElement;

  // Listen for and trap the keyboard
  mobileMenu.addEventListener('keydown', trapTabKey);

  // Listen for indicators to close the modal
  menuOverlay.addEventListener('click', closeMenu);

  // Modal close buttons
  const closeButtons = mobileMenu.querySelectorAll('[data-close-menu]');

	// Attach listeners to all the close modal buttons
	for(let i = 0; i < closeButtons.length; i++) {
		closeButtons[i].addEventListener('click', closeMenu);
	}

  // Find all focusable children
  const focusableElementsString = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]';
  let focusableElements = mobileMenu.querySelectorAll(focusableElementsString);

  // Convert NodeList to Array
  focusableElements = Array.prototype.slice.call(focusableElements);

  const firstTabStop = focusableElements[0];
  const lastTabStop = focusableElements[focusableElements.length - 1];

  // Show the modal and overlay
  // mobileMenu.style.display = 'block';
  // menuOverlay.style.display = 'block';
  menuToggle.setAttribute("aria-expanded", "true");
  mobileMenu.setAttribute("data-menu-closed", "false");
  mobileMenu.setAttribute("aria-hidden", "false");

	// This just allows for the animation, not required.
	setTimeout(function() {
		mobileMenu.classList.remove('is-closed');
	}, 10);

  // Focus first child
  firstTabStop.focus();

  function trapTabKey(e) {
    // Check for TAB key press
    if (e.keyCode === 9) {

      // SHIFT + TAB
      if (e.shiftKey) {
      	if (document.activeElement === firstTabStop) {
      		e.preventDefault();
      		lastTabStop.focus();
      	}

      // TAB
    } else {
     if (document.activeElement === lastTabStop) {
      e.preventDefault();
      firstTabStop.focus();
    }
  }
}

    // ESCAPE
    if (e.keyCode === 27) {
    	closeMenu();
    }
  }
}

/**
 * Closes the modal.
 */
 function closeMenu() {

	// Animate the close
	mobileMenu.classList.add('is-closed');
  menuToggle.setAttribute("aria-expanded", "false");
  mobileMenu.setAttribute("aria-hidden", "true");
	// This setTimeout just allows for the animation, not required.
	setTimeout(function() {
		 // Hide the modal and overlay
		 // mobileMenu.style.display = 'none';
		 // menuOverlay.style.display = 'none';
		 mobileMenu.setAttribute("data-menu-closed", "true");
		}, 300);
	

  // Set focus back to element that had it before the modal was opened
  focusedElementBeforeMenu.focus();
}