const supportsNativeSmoothScroll = function (elem) {
    return elem && "getComputedStyle" in window &&
    window.getComputedStyle(elem)["scroll-behavior"] === "smooth";
};

/* Explicitly grab manually set [data-scroll] links */
const scrollLinks = document.querySelectorAll("[data-scroll]");

if (!supportsNativeSmoothScroll(document.documentElement)) {
    // console.warn("Native smooth scroll not supported, polyfilling...");
    const scrollLinks = document.querySelectorAll("[data-scroll]");

    const scrollPolyfill = function(e) {
       let anchorDestination = this.getAttribute("href");
       anchorDestination = anchorDestination.replace("#", "");
       document.getElementById(anchorDestination).scrollIntoView({
          behavior: 'smooth'
      });
   };

   for (let i = 0; i < scrollLinks.length; i++) {
       scrollLinks[i].addEventListener('click', scrollPolyfill, true);
   }
} else {
    // console.info("✅ Native smooth scrolling is supported.");
}